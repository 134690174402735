// Generated by Framer (4f2ee3e)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useConstant, useIsOnFramerCanvas, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {uWCv3oeUR: {hover: true}};

const cycleOrder = ["uWCv3oeUR"];

const serializationHash = "framer-UgdQf"

const variantClassNames = {uWCv3oeUR: "framer-v-11ndvdc"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, link, title, width, ...props}) => { return {...props, fjsl2Ulsy: title ?? props.fjsl2Ulsy ?? "تواصل معنا", NIp5TQ9_I: link ?? props.NIp5TQ9_I} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;link?: string;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, NIp5TQ9_I, fjsl2Ulsy, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "uWCv3oeUR", enabledGestures, transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const isOnCanvas = useIsOnFramerCanvas()

const initialVariant = useConstant(() => variant)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Transition value={transition}><Link href={NIp5TQ9_I} openInNewTab={false}><motion.a {...restProps} animate={variants} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-11ndvdc", className, classNames)} framer-1ojdh9z`} data-framer-name={"Variant 1"} initial={isOnCanvas ? variant : initialVariant } layoutDependency={layoutDependency} layoutId={"uWCv3oeUR"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{backgroundColor: "var(--token-bd573de2-a99b-47a0-86f9-5c1241a6fd64, rgb(0, 71, 187))", borderBottomLeftRadius: 28, borderBottomRightRadius: 28, borderTopLeftRadius: 28, borderTopRightRadius: 28, ...style}} variants={{"uWCv3oeUR-hover": {backgroundColor: "rgb(84, 147, 247)"}}} {...addPropertyOverrides({"uWCv3oeUR-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "Q1VTVE9NO0lCTSBQbGV4IFNhbnMgQXJhYmljIE1lZGl1bQ==", "--framer-font-family": "\"IBM Plex Sans Arabic Medium\", \"IBM Plex Sans Arabic Medium Placeholder\", sans-serif", "--framer-font-size": "15px", "--framer-letter-spacing": "-0.01em", "--framer-line-height": "2em", "--framer-text-alignment": "left", "--framer-text-color": "var(--extracted-r6o4lv, var(--token-d59b9de7-914a-416b-b35c-0bc1e3fd3641, rgb(255, 255, 255)))", direction: "rtl"}}>تواصل معنا</motion.p></React.Fragment>} className={"framer-fh9x4z"} fonts={["CUSTOM;IBM Plex Sans Arabic Medium"]} layoutDependency={layoutDependency} layoutId={"snsQzg4By"} style={{"--extracted-r6o4lv": "var(--token-d59b9de7-914a-416b-b35c-0bc1e3fd3641, rgb(255, 255, 255))"}} text={fjsl2Ulsy} verticalAlignment={"top"} withExternalLayout/></motion.a></Link></Transition>
</LayoutGroup>)

});

const css = [".framer-UgdQf[data-border=\"true\"]::after, .framer-UgdQf [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-UgdQf.framer-1ojdh9z, .framer-UgdQf .framer-1ojdh9z { display: block; }", ".framer-UgdQf.framer-11ndvdc { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 40px; justify-content: center; overflow: visible; padding: 15px 15px 15px 15px; position: relative; text-decoration: none; width: auto; }", ".framer-UgdQf .framer-fh9x4z { -webkit-user-select: none; flex: none; height: auto; position: relative; user-select: none; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-UgdQf.framer-11ndvdc { gap: 0px; } .framer-UgdQf.framer-11ndvdc > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-UgdQf.framer-11ndvdc > :first-child { margin-left: 0px; } .framer-UgdQf.framer-11ndvdc > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 40
 * @framerIntrinsicWidth 94
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","fixed"]},"SAmHxjcV9":{"layout":["auto","fixed"]}}}
 * @framerVariables {"NIp5TQ9_I":"link","fjsl2Ulsy":"title"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const Framerwl4_PwBwf: React.ComponentType<Props> = withCSS(Component, css, "framer-UgdQf") as typeof Component;
export default Framerwl4_PwBwf;

Framerwl4_PwBwf.displayName = "Contact AR";

Framerwl4_PwBwf.defaultProps = {height: 40, width: 94};

addPropertyControls(Framerwl4_PwBwf, {NIp5TQ9_I: {title: "Link", type: ControlType.Link}, fjsl2Ulsy: {defaultValue: "تواصل معنا", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(Framerwl4_PwBwf, [{family: "IBM Plex Sans Arabic Medium", source: "custom", url: "https://framerusercontent.com/assets/c5ApvwGMskEGuIXW8X0BN1liRA.ttf"}])